<template>
	<div id="contact" class="contact" ref="contact">
		<!-- 头部 nav导航 -->
		<header-nav :tab="true"></header-nav>
		<!-- 关于我们-->
		<div class="content-box">
			<!-- 小屏 -->
			<div class="about-logo" v-if="is_vertical">
				<img src="/images/contact-bg.png" style="width: 100%;height: 100%;"/>
			</div>
			<div class="about-logo-top">
				<div class="contact-space-box">
					<div style="uni-flex-column">
						<div class="contact-list" :class="index%2?'contact-list-blue':''" v-for="(item,index) in contactList" :key="index"  @click="goHandle(item)">
							<div class="t2">{{item.memo}}</div>
							<div style="display: flex;align-items: center;" v-if="item.kay=='cfg_company_qq'">
								<div class="t1" id="company_qq" >{{item.val}}</div>
								<div class="t1-1">(点击复制)</div>
							</div>
							<div class="uni-flex" v-else-if="item.kay=='cfg_company_wx'">
								<div class="t1" id="company_wx" >{{item.val}}</div>
								<div class="t1-1">(点击复制)</div>
							</div>
							<div class="uni-flex" v-else-if="item.kay=='cfg_company_phone'">
								<div class="t1">{{item.val}}</div>
								<div class="t1-1" v-if="is_vertical">(点击拨号)</div>
							</div>
							<div class="uni-flex" v-else>
								<div class="t1">{{item.val}}</div>
							</div>
							<!-- <div class="t1" id="company_qq" v-if="item.kay=='cfg_company_qq'">{{item.val}} <div class="t1-1">(点击复制)</div></div>
							<div class="t1" id="company_wx" v-else-if="item.kay=='cfg_company_wx'">{{item.val}} <div class="t1-1">(点击复制)</div></div>
							<div class="t1" v-else-if="item.kay=='cfg_company_phone'">{{item.val}} <div class="t1-1">(点击拨号)</div></div>
							<div class="t1" v-else>{{item.val}}</div> -->
						</div>
					</div>
					<div style="width: 100%;" :style="is_vertical?'margin-top:30px;':'margin-left:30px;'">
						<testMap @gettude="gettude" :tude="form.longitude+','+form.latitude" :citys="city" :is_vertical="is_vertical" ></testMap>
					</div>
				</div>
				
				<div class="about-logo2">
					<img src="/images/contact-bg2.png" style="width: 100%;height: 100%;"/>
				</div>
				
				<div class="submit-area">
					<input class="input-box" v-model="formData.name" placeholder='您的姓名'/>
					<input class="input-box" v-model="formData.mobile" placeholder='您的电话'/>
					<input class="input-box" v-model="formData.weixin" placeholder='您的微信'/>
					<input class="input-box" type="text" v-model="formData.info" placeholder='您的留言'/>
					<div class="sub-btn" @click="submit">提交</div>
				</div>
			</div>
		</div>
		
		<!-- 底部链接 -->
		<!-- 友情链接 -->
		<section class="friend uni-flex-column uni-flex-center" style="margin-top: 30px;">
			<div class="friend-content">
				<div class="friend-text">
					<span>友情链接：</span>
					<a href="https://shop117115684.taobao.com/?spm=a2142.8996326.sellercard.15.146f2be3VrqbMR">千雪制冷淘宝店</a>
				</div>
			</div>
		</section>
		<!-- 联系我们 -->
		<!-- 小屏 -->
		<section class="foot uni-flex-column uni-flex-center" ref="contact" v-if="is_vertical">
			<div class="foot-content">
				<div class="foot-logo">
					<img src="/images/qrcode.png" style="width: 100%;height: 100%;"/>
				</div>
				<div class="foot-scan">微信扫一扫</div>
				<div class="foot-text">
					<span>copyright © 2020-2021 ALL RIGHT RESERVED 宁德市千雪制冷设备有限公司 版权所有</span>
					<span>地址：宁德市蕉城区古溪新村23号</span>
					<span>备案号：闽ICP备20014190号</span>
					<span>电话：13616088271</span>
				</div>
			</div>
		</section>
		<!-- 大屏 -->
		<section class="foot uni-flex-column uni-flex-center" ref="contact" v-else>
			<div class="foot-content">
				<div class="foot-text">
					<span>copyright © 2020-2021 ALL RIGHT RESERVED 宁德市千雪制冷设备有限公司 版权所有</span>
					<span>地址：宁德市蕉城区古溪新村23号</span>
					<span>备案号：闽ICP备20014190号</span>
					<span>电话：13616088271</span>
				</div>
				<div class="foot-logo">
					<img src="/images/qrcode.png" style="width: 100%;height: 100%;"/>
				</div>
			</div>
		</section>
	</div>
</template>

<script>
	import {Toast} from 'vant';
	import api from '@/utils/api.js';
	import headerNav from '@/components/header.vue';
	import testMap from './test-map.vue';
	export default {
		name: 'contact', //联系我们
		components: {
			headerNav,
			testMap
		},
		data() {
			return {
				is_vertical: false,  //false 小屏  true 大屏
				win_width: document.body.clientWidth,
				form: {
					longitude: '119.536565',
					latitude: '26.643559'
				},
				city: ['宁德'],
				contactList: [
					// {
					// 	memo: '联系人',
					// 	val: '兰经理',
					// 	flag: false,
					// },
					// {
					// 	memo: '联系电话',
					// 	val: '13616088271',
					// 	flag: false,
					// },
					// {
					// 	memo: 'QQ',
					// 	val: '371562931',
					// 	flag: true,
					// },
					// {
					// 	memo: '微信',
					// 	val: 'llan4018',
					// 	flag: false,
					// },
					// {
					// 	memo: '淘宝店铺',
					// 	val: '宁德市千雪制冷设备',
					// 	flag: false,
					// },
					// {
					// 	memo: '公司地址',
					// 	val: '福建省宁德市蕉城区古溪新村23号',
					// 	flag: false,
					// },
					// {
					// 	memo: '服务时间',
					// 	val: '9:00-18:00 周一到周五',
					// 	flag: false,
					// },
				],
				formData: {
					name: '',
					mobile: '',
					weixin: '',
					info: ''
				},
				info: {
					phone: '',
					addr: '',
					qq: '',
					wx: '',
					servicetime: ''
				}
			}
		},

		computed: {
			
		},
		created() {
			window.scrollTo({
			    left: 0,
			    top: 0,
			    behavior: 'smooth'
			})
			this.api_settingInfo()
		},
		mounted() {
			/* 获取页面高宽 */
			// this.body_width = document.getElementById('contact').offsetWidth;
			// this.body_height = document.getElementById('contact').clientHeight;
			const that = this;
			window.onresize = () => {
				return (() => {
					window.screenWidth = document.body.clientWidth;
					that.win_width = window.screenWidth;
				})();
			};
			console.log(this.win_width);
			if (this.win_width < 768) {
				//小屏
				this.is_vertical = true;
			} else if (this.win_width >= 768) {
				//大屏
				this.is_vertical = false;
			}
		},
		watch: {
		},
		methods: {
			api_settingInfo() {
				api.settingInfoList().then(res=>{
					console.log(res);
					if(res.code == 0){
						this.contactList = [{},{},{},{},{},{},{}];
						let list = res.data;
						list.forEach(item => {
							if(item.kay == "cfg_company_servicetime") {
								this.contactList[6] = item;
							}
							else if(item.kay == "cfg_company_addr") {
								this.contactList[5] = item;
							}
							else if(item.kay == "cfg_company_qq") {
								this.contactList[2] = item;
							}
							else if(item.kay == "cfg_company_wx") {
								this.contactList[3] = item;
							}
							else if(item.kay == "cfg_company_phone") {
								this.contactList[1] = item;
							}
							else if(item.kay == "cfg_company_tb") {
								this.contactList[4] = item;
							}
							else if(item.kay == "cfg_company_contact") {
								this.contactList[0] = item;
							}
						});
					}
				})
			},
			gettude(val) {
			  console.log(val);
			  let tudes = val.split(',')
			  console.log(tudes);
			  this.form.longitude = tudes[0]
			  this.form.latitude = tudes[1]
			},
			//提交
			submit() {
				if(!this.formData.name) {
					Toast('姓名不可为空');
					return;
				}
				if(!this.formData.mobile) {
					Toast('手机不可为空');
					return;
				}
				if(!this.formData.info) {
					Toast('备注不可为空');
					return;
				}
				let param = {
				  content: JSON.stringify(this.formData),
				  imgs: []
				};
				api.feedback(param).then(res => {
					if(res.code==0) {
						Toast("提交成功");
						this.formData = {
							name: '',
							mobile: '',
							weixin: '',
							info: ''
						}
					}else{
						Toast(res.msg);
					}
				});
				
			},
			goHandle(item) {
				if(item.kay == 'cfg_company_phone') { //拨打电话
					window.location.href = 'tel:'+item.val
				}
				else if(item.kay == 'cfg_company_qq') { //复制qq号
					this.cp(document.getElementById('company_qq'))
				}
				else if(item.kay == 'cfg_company_wx') { //复制微信号
					this.cp(document.getElementById('company_wx'))
				}
			},
			selectText(x) {
			    if (document.selection) {
			        var range = document.body.createTextRange();
			        range.moveToElementText(x);
			        range.select();
			    } else if (window.getSelection) {
			        var selection = window.getSelection();
			        var range = document.createRange();
			        selection.removeAllRanges();
			        range.selectNodeContents(x);
			        selection.addRange(range);
			    }
			},
			cp(x)
			{
			    this.selectText(x);
			    document.execCommand("copy");
			    alert("复制成功,快去添加好友吧")
			}
		}
	}
</script>

<style scoped="" lang="less">
	.yx-h5 {
		font-size: 14px;
	}

	.contact {
		height: 100%;
		overflow: auto;
		font-size: 20px;
		color: #bdbdbd;
		background-color: #FFFFFF;
	}
	
	.content-box {
		display: flex;
		flex-direction: column;
		align-items: center;
		width: 100%;
		.about-logo {
			width: 100%;
			height: 900px;
		}
		
		.about-logo-top {
			width: 1200px;
			margin: 50px 15px;
			text-align: left;
			
			.contact-space-box {
				display: flex;
				justify-content: space-between;
				
				.contact-list {
					padding: 15px 0;
					width: 400px;
					height: 90px;
					background: #FFFFFF;
					border: 1px solid #DBDBDB;
				}
				.contact-list-blue {
					background: #0E82D9;
					color: #FFFFFF;
					.t1,.t2 {
						color: #FFFFFF;
					}
				}
				
				.t1 {
					margin-left: 10px;
					margin-top: 20px;
					font-size: 20px;
					font-family: Source Han Sans CN;
					font-weight: bold;
					color: #111111;
					line-height: 20px;
					display: flex;
				}
				.t1-1 {
					margin-top: 20px;
					margin-left: 10px;
					font-size: 14px;
				}
				.t2 {
					margin-top: 20px;
					margin-left: 10px;
					font-size: 16px;
					font-family: Source Han Sans CN;
					font-weight: 500;
					color: #999999;
					line-height: 20px;
				}
				
			}
			
			.about-logo2 {
				margin: 30px 0;
				width: 100%;
				height: 600px;
			}
			
			.submit-area {
				width: 100%;
				height: 400px;
				border: 1px solid #DBDBDB;
				margin: 50px 0;
				.input-box {
					width: 90%;
					font-size: 16px;
					background-color: #FFFFFF;
					margin: 30px;
					border-radius: 0px;
					padding: 10px;
					// width: 320px;
					display: flex;
					flex-direction: row;
					justify-content: space-between;
					align-items: center;
					border: 0px solid #DBDBDB;
					border-bottom: 1px solid #DBDBDB;
				}
				.sub-btn {
					margin: 20px;
					width: 95%;
					height: 50px;
					background: #1166BB;
					display: flex;
					justify-content: center;
					align-items: center;
					color: #FFFFFF;
				}
			}
		}
		
	}

	.friend {
		margin-top: 30px;
		height: 50px;
		background-color: #F5F5F5;
		
		.friend-content {
			display: flex;
			flex-direction: wrap;
			width: 1200px;
			
			.friend-text {
				font-size: 16px;
				font-family: Microsoft YaHei;
				font-weight: 400;
				color: #000000;
				line-height: 24px;
			}
		}
	}
	
	.foot {
		height: 200px;
		background-color: #0E82D9;
		
		.foot-content {
			display: flex;
			justify-content: space-between;
			align-items: center;
			width: 1200px;
			
			.foot-text {
				display: flex;
				flex-direction: column;
				text-align: left;
				font-size: 16px;
				font-family: Microsoft YaHei;
				font-weight: 400;
				color: #FFFFFF;
				line-height: 24px;
				.span {
				}
			}
			
			.foot-logo {
				width: 142px;
				height: 142px;
			}
		}
	}

	/* 手机端 */
	@media (orientation:portrait) and (max-width:890px) {
		
		.contact {
			overflow-y: auto;
			overflow-x: hidden;
		}
		
		.content-box {
			width: 100%;
			.about-logo {
				width: 100%;
				height: 300px !important;
			}
			
			.about-logo-top {
				width: 90%;
				margin: 30px 0;
				text-align: left;
				
				.contact-space-box {
					display: flex;
					flex-direction: column;
					justify-content: space-between;
					
					.contact-list {
						padding: 15px 0px;
						width: 100%;
						height: 60px;
						background: #FFFFFF;
						border: 1px solid #DBDBDB;
					}
					.contact-list-blue {
						background: #0E82D9;
						color: #FFFFFF;
						.t1 {
							color: #FFFFFF;
						}
					}
					.t1 {
						margin-top: 10px;
						margin-left: 10px;
						font-size: 18px;
						line-height: 20px;
					}
					.t1-1 {
						margin-top: 10px;
						margin-left: 10px;
						font-size: 14px;
					}
					.t2 {
						margin-top: 0px;
						margin-left: 10px;
						font-size: 14px;
						line-height: 20px;
					}
				}
				
				.about-logo2 {
					margin: 30px 0;
					width: 100%;
					height: 200px;
				}
				
				.submit-area {
					.input-box {
						width: 80%;
					}
					.sub-btn {
						width: 90%;
					}
				}
			}
			
			
			
			
		}
		
		.friend {
			margin-top: 30px;
			height: 30px;
			background-color: #F5F5F5;
			
			.friend-content {
				display: flex;
				flex-direction: wrap;
				width: 100%;
				margin-left: 10px;
				.friend-text {
					font-size: 14px;
					font-family: Microsoft YaHei;
					font-weight: 400;
					color: #000000;
					line-height: 20px;
				}
			}
		}
		
		.foot {
			// margin-top: 30px;
			height: 370px;
			background-color: #0E82D9;
			
			.foot-content {
				display: flex;
				flex-direction: column;
				width: 100%;
				.foot-scan {
					padding: 20px 0;
					text-align: center;
					font-size: 12px;
					color: #FFFFFF;
				}
				.foot-text {
					margin-top: 20px;
					text-align: center;
					font-size: 12px;
				}
				
				.foot-logo {
					width: 120px;
					height: 120px;
				}
			}
		}

		.header .content .nav {
			display: none !important;
		}

		.content {
			justify-content: space-between !important;
			padding: 0 20px;

			.clear {
				display: none;
			}

			.nav-min {
				display: flex !important;
			}

			.langs {
				display: none;
			}
		}
	}
</style>
