<template>
	<div>
		<template slot="header">
			高德地图
		</template>
		<div class="map-box">
			<!-- <el-amap-search-box class="search-box" :search-option="searchOption" :on-search-result="onSearchResult"></el-amap-search-box> -->
			<el-amap class="amap-box" :zoom="zoom" :vid="'amap-vue'" :plugin="plugin" :center="center" :events="events">
				<el-amap-marker :position="markers.position" :events="markers.events" :visible="markers.visible" :draggable="markers.draggable"
				 :vid="markers.vid" :label="markers.label1" v-if="is_vertical">
				</el-amap-marker>
				<el-amap-marker :position="markers.position" :events="markers.events" :visible="markers.visible" :draggable="markers.draggable"
				 :vid="markers.vid" :label="markers.label" v-else>
				</el-amap-marker>
				<!-- <el-amap-text :text="markers.text" :offset="markers.offset"></el-amap-text> -->
			</el-amap>
		</div>
		<!-- <div class="toolbar">
			<span v-if="loaded">
				<div>
					<span>经纬度：</span>
					{{tudes}}
				</div>
			</span>
			<span v-else>正在定位</span>
		</div> -->
	</div>
</template>

<script>
	export default {
		name: 'test-map', //高德地图
		props: {
			tude:{
				type:String,
				default:''
			},
			citys:{
				type:Array,
				default:''
			},
			is_vertical:{
				type:Boolean,
				default:false
			},
		},
		data() {
			let self = this;
			self.city = self.citys[0] || '';
			return {
				zoom: 14,
				center: [119.546027,26.649746],
				tudes: '',
				lng: 0,
				lat: 0,
				loaded: false,
				searchOption: {
					city:"宁德",
					citylimit: true
				},
				plugin: [{
					pName: 'Geolocation',
					events: {
						init(o) {
							// o 是高德地图定位插件实例
							// o.getCurrentPosition((status, result) => {
							// 	if (result && result.position) {
							// 		console.log(result);
							// 		self.lng = result.position.lng;
							// 		self.lat = result.position.lat;
							// 		self.center = [self.lng, self.lat];
							// 		self.loaded = true;
							// 	}
							// });
						}
					}
				}],
				markers: {
					vid: 1,
					position: [119.546027,26.649746],
					events: {
						click: () => {
							//alert('click marker');
						},
						dragend: e => {
							console.log('---event---: dragend');
							this.marker[0].position = [e.lnglat.lng, e.lnglat.lat];
						}
					},
					visible: true,
					draggable: false,
					template: '<span>1</span>',
					label: {
					  content: `<div style="display:flex;flex-direction:column;font-size:14px;color:#000000;"><span>宁德市千雪制冷设备有限公司</span><span style="font-size:12px;color:#999999;margin-top:5px;">冷库设计制作安装，冷柜，风幕柜，保鲜库，冷冻库，速冻库，各种制冰机，工业冷水机，制冷半封闭压缩机，中央空调等</span></div> `,
					  offset: [-250,-50]
					},
					label1: {
					  content: `<div style="display:flex;flex-direction:column;font-size:14px;color:#000000;"><span>宁德市千雪制冷设备有限公司</span></div> `,
					  offset: [-50,-30]
					},
				},
				
				events: {
					 init: (o) => {
					             o.setCity(self.city,result => {
					                console.log("----------setCity",result);
					                if(result && result.length > 0){
					                    self.zoom = 16;
					                    self.markers.position = result;
					                    self.getList(result);
					                }
					             });
					             //去掉logo
					             // document.getElementsByClassName("amap-logo")[0].style.display = "none";
					            },
					click(e) {
						// alert(1)
						console.log(e.lnglat);
						let {
							lng,
							lat
						} = e.lnglat;
						self.lng = lng;
						self.lat = lat;
						self.markers.position = [self.lng, self.lat];
						self.loaded = true;
						// 这里通过高德 SDK 完成。
						var geocoder = new AMap.Geocoder({
							radius: 1000,
							extensions: 'all'
						});
						geocoder.getAddress([lng, lat], function(status, result) {
							if (status === 'complete' && result.info === 'OK') {
								if (result && result.regeocode) {
									self.markers.position[0] = self.lng;
									self.markers.position[1] = self.lat;
									self.markers.visible = false;
									self.address = result.regeocode.formattedAddress;
									self.$nextTick(() => {
										self.markers.visible = true;
									});
								}
							}
						});
					}
				}
			};
		},
		created() {
			this.tudes = this.tude;
			console.log(this.tude);
			console.log(this.tudes);
		},
		computed: {
		},
		methods: {
			gettude() {
				this.$emit('gettude', this.tudes);
			},
			addMarker: function() {
				let lng = 121.5 + Math.round(Math.random() * 1000) / 10000;
				let lat = 31.197646 + Math.round(Math.random() * 500) / 10000;
				this.markers.position = [lng, lat];
			},
			onSearchResult(pois) {
				console.log(pois);
				let searchMarker = pois[0];
				this.markers.position = [searchMarker.lng, searchMarker.lat];
				this.center = [searchMarker.lng, searchMarker.lat];
				this.loaded = true;
				this.lng = searchMarker.lng;
				this.lat = searchMarker.lat;
			}
		},
		watch: {
			lng(value) {
				this.tudes = value + ',' + this.lat;
				this.gettude();
			}
		}
	};
</script>

<style scoped="">
	.map-box {
		width: 100%;
		height: 850px;
		position: relative;
	}

	.search-box {
		position: absolute;
		top: 25px;
		left: 20px;
	}
</style>
